import { compare } from '../../../../webmodule-common/other/clone';
import { customElement, property, query, state } from 'lit/decorators.js';
import { DashboardWidget } from './dashboard-widget';
import { DateTime } from 'luxon';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid';
import { getApiFactory } from '../../../api/api-injector';
import { html } from 'lit';
import { isEmptyOrSpace } from '../../../../webmodule-common/other/ui/string-helper-functions';
import {
  PurchaseOrderSummary,
  ResultGetPurchaseOrderList
} from '../../../api/supplier-api-interface-franchiseenetwork';
import { serverDateTimeToLocal } from '../../../../webmodule-common/other/datetime-converter';
import { Snippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../../webmodule-common/components/src/webmodule-components';

export class DeliveryPipelinePoTablePageCache {
  _pageKey = this.pageKey(-1, -1, '', false, undefined, undefined);
  _pagePromise: Promise<ResultGetPurchaseOrderList | null> | null = null;

  pageKey(
    index: number,
    length: number,
    sortField: string,
    sortAsc: boolean,
    branchId?: string,
    assignedToUserId?: string
  ): {
    index: number;
    length: number;
    sortField: string;
    sortAsc: boolean;
    branchId?: string;
    assignedToUserId?: string;
  } {
    return { index, length, sortAsc, sortField, branchId: branchId, assignedToUserId };
  }

  async getData(
    index: number,
    length: number,
    sortField: string,
    sortAsc: boolean,
    branchId?: string,
    assignedToUserId?: string
  ) {
    const currentPage = this.pageKey(index, length, sortField, sortAsc, branchId, assignedToUserId);
    if (!compare(this._pageKey, currentPage)) {
      this._pageKey = currentPage;
      this._pagePromise = getApiFactory()
        .dataAggregation()
        .getPurchaseOrderList({
          pageIndex: this._pageKey.index,
          pageSize: this._pageKey.length,
          sortField: this._pageKey.sortField,
          sortAsc: this._pageKey.sortAsc,
          branchId: this._pageKey.branchId ?? emptyGuid,
          assignedToUserId: this._pageKey.assignedToUserId ?? emptyGuid
        });
    }
    return this._pagePromise;
  }
}

@customElement('delivery-pipeline-po-widget')
export class DeliveryPipelinePoWidget extends DashboardWidget {
  @property({ attribute: false })
  public branchId?: string;
  @property({ attribute: false })
  public assignedToUserId?: string;
  @property({ attribute: false })
  public cache?: DeliveryPipelinePoTablePageCache;
  @query('#delivery-pipeline-po-widget')
  protected table!: WebModuleLitTable;
  @state()
  private data: PurchaseOrderSummary[] = [];

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%v6-reference%%`,
        classes: 'col-max-px-120 v6-reference',
        fieldName: 'reference',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderSummary;
          //replaced code. reference is not a number. its a qualified formatted
          //string that holds a v6 quote num-pref-suff if v6 is the source
          //use build in function, matches dealer, and inbox
          return isEmptyOrSpace(rowItem.reference) ? tlang`N/A` : html`${rowItem.reference}`;
        }
      },
      {
        title: tlang`%%purchase-order-supplier-system-status%%`,
        classes: 'col-max-px-100 purchase-order-supplier-system-status',
        fieldName: 'supplierSystemStatus',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderSummary;

          return html`${rowItem.supplierSystemStatus}`;
        }
      },
      {
        title: tlang`%%franchisee%%`,
        classes: 'colpx-20 dealership',
        fieldName: 'customerName',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderSummary;

          return html`${this.lookupFranchiseeName(rowItem.franchiseeTenantId)}`;
        }
      },
      {
        title: tlang`%%branch%%`,
        classes: 'colpx-20 branch',
        fieldName: 'branchName',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderSummary;
          return html`${this.lookupBranchName(rowItem.branchId)}`;
        }
      },
      {
        title: tlang`%%purchase-order-description%%`,
        classes: 'colpx-60 purchase-order-description',
        fieldName: 'title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderSummary;

          return html`${rowItem.title}`;
        }
      },
      {
        title: tlang`%%purchase-order-supplier-delivery-date%%`,
        classes: 'col-max-px-100 purchase-order-supplier-delivery-date no-pseudo',
        fieldName: 'supplierSystemDeliveryDate',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderSummary;
          return rowItem.supplierSystemDeliveryDate == null
            ? ''
            : serverDateTimeToLocal(rowItem.supplierSystemDeliveryDate).toLocaleDateString();
        }
      }
    ];
  }

  lookupFranchiseeName(franchiseeTenantId: number): string {
    return (
      userDataStore.allFranchiseeDetails.tenantList.tenants?.find(x => x.id === franchiseeTenantId)?.companyName ??
      tlang`<unavailable>`
    );
  }

  lookupBranchName(branchId: string): string {
    return (
      userDataStore.allFranchiseeDetails.branches?.find(x => x.branchId === branchId)?.abbreviation ??
      tlang`<unavailable>`
    );
  }

  protected getHeader(): Snippet {
    return tlang`Delivery Pipeline`;
  }

  protected isNearDeadlineOrOverdue(data: PurchaseOrderSummary): boolean {
    const installDate = data.supplierSystemDeliveryDate ?? data.installationDate;
    const installationDate = installDate ? serverDateTimeToLocal(installDate) : null;

    return installationDate != null && installationDate < DateTime.now().plus({ days: 3 }).toJSDate();
  }

  protected getBody(): Snippet {
    // eslint-disable-next-line consistent-return
    const getRowClass = (item: PurchaseOrderSummary) => {
      if (this.isNearDeadlineOrOverdue(item)) {
        return 'table-danger';
      }
    };

    return html`
      <div class="delivery-pipeline-po-widget">
        <webmodule-lit-table
          id="delivery-pipeline-po-widget"
          .columns=${this.getColumns()}
          .data=${this.data}
          tablestyle="nestedtable"
          pageLength="20"
          .clickrows=${false}
          .itemRowClassEvent=${getRowClass}
          @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        >
        </webmodule-lit-table>
      </div>
    `;
  }

  private async internalDataLoad(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 16;

    const results = await this.cache?.getData(
      index,
      length,
      'SupplierSystemDeliveryDate',
      false,
      this.branchId,
      this.assignedToUserId
    );

    if (results) {
      this.data = results.purchaseOrders;
      this.table.rowCount = results.totalCount;
    }
  }
}
