import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { getApiFactory } from '../../api/api-injector';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit';
import { ImageUploader, VirtualFile } from '../data/image-upload';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { marked } from 'marked';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

@autoElement()
export class SettingsLoginImageBrandingView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataBinding: DataBinding;
  private imageUploader: ImageUploader;

  private loginImageName = 'supplier-login-image';

  constructor() {
    super();
    this.pageFragment = 'loginImage';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.imageUploader = new ImageUploader({
      dataBinding: this.dataBinding
    });
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Login Image`;
  }
  getValidationErrors(): string[] {
    const errors: string[] = [];

    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (
      this.imageUploader.elementHasFile(this.loginImageName) &&
      !this.imageUploader.elementHasImageFile(this.loginImageName)
    ) {
      errors.push(tlang`Please provide a valid image`);
    }
    return errors;
  }

  async prepareForSave(): Promise<void> {
    //nothing to do;
  }
  public internalDataChanged(): boolean {
    return this.imageUploader.elementHasFile(this.loginImageName);
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    await this.render();
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a image from the given element and get its uploaded file path
    const newloginImageVirtualPath = await this.getBase64ImageFromElement(this.loginImageName);

    // use the new path of the image if it was uploaded, or the existing image path
    this.settingsManager.updateInput.base64LoginImage = newloginImageVirtualPath?.content ?? null;
    this.settingsManager.updateInput.loginImageFileName = newloginImageVirtualPath
      ? `loginImage${newloginImageVirtualPath.extension}`
      : null;

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Login Image saved`);
      await this.render();
      return true;
    }

    return false;
  }

  /**
   * Attempts to upload a image from the specified element and returns its virtual file path if successful, or returns null if there is no image to upload.
   * If successful, it removes the local file from the specified element.
   * @param fieldName The field name of the element that the file will be uploaded from.
   * @param newFileName The new name of the uploaded file.
   * @returns A promise with the file path if the file was uploaded, or null if it wasn't.
   */
  private async getBase64ImageFromElement(fieldName: string): Promise<VirtualFile | null> {
    const fileInformation = await this.imageUploader.getVirtualFileFromElement(fieldName);
    // only attempt an upload if we have information about the image file to be uploaded.
    if (fileInformation) {
      // get the virtual image path from the API and attempt to upload the file content.

      // if successful remove the local uploaded file to avoid repeated uploads.
      this.dataBinding.removeFiles(fieldName);
      return fileInformation;
    }
    // if we get here no image has been uploaded, so return null as the path.
    return null;
  }

  /**
   * Attempts to get the absolute virtual file path for a given relative file path.
   * @param relativeVirtualFilePath The relative virtual file path.
   * @returns The full absolute virtual file path.
   */
  private getAbsoluteVirtualFilePath(relativeVirtualFilePath: string | null | undefined): string {
    if (relativeVirtualFilePath) return getApiFactory().blob().fullUrl(relativeVirtualFilePath);
    else return '';
  }

  protected async bodyTemplate(): PromiseTemplate {
    const fld = (name: string) => this.dataBinding.field(name);

    const brandingMsg = marked.parse(
      tlang`${'ref:SettingsLoginImageBrandingView:uploadMsg:1'}
Please upload your custom login screen image<br>

The minimum permitted size is **1920(W) x 1080(H)** pixels.<br>
The image must be in **jpg** or **png** format.<br>
Please note that unoptimized images may increase the page loading time.<br>
If the aspect ratio of your image is different, it will be scaled down in proportion to fit the template constraints.
`,
      { async: false }
    ) as string;

    return html` <div>
      <form id="LoginImageForm" class="form-two-col">
        <div class="row branding-wrapper">
          <div class="col-sm-12 form-column">
            <h4 class="section-header branding-image-header">${tlang`Login Image`}</h4>
            <div class="branding-image-description">
              ${tlang`This image appears on the login and password reset pages`}
            </div>
            <bs-form-image-upload
              data-id=${fld(this.loginImageName)}
              .value="${this.getAbsoluteVirtualFilePath(this.settings?.loginImageVirtualPath)}"
              data-label=${tlang`Login Screen Image`}
            ></bs-form-image-upload>
          </div>
        </div>
        <h2>${tlang`Specifications`}:</h2>
        <p>${unsafeHTML(brandingMsg)}</p>
      </form>
    </div>`;
  }
}
