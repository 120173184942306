import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { fileToBase64 } from '../../../webmodule-common/other/blob-converters';
import { getApiFactory } from '../../api/api-injector';

export interface ImageUploadOptions {
  dataBinding: DataBinding;
}

/**
 * Defines basic information about a virtual file.
 */
export interface VirtualFile {
  /** Name of the file. */
  name: string;
  /** The file extension of the file. */
  extension: string;
  /** The content of the file. */
  content: string;
  /** The content type of the file. */
  type: string;
  /** The virtual filepath for the file. */
  filePath: string;
}

export class ImageUploader {
  private dataBinding: DataBinding;
  private blobApi = getApiFactory().blob();

  constructor(options: ImageUploadOptions) {
    this.dataBinding = options.dataBinding;
  }

  /**
   * Attempts to get required information about a file from the specified input element. If no file has been uploaded null will be returned.
   * @param fieldName The field id of the element that the file will be extracted from.
   * @returns The extracted file information or null.
   */
  public async getVirtualFileFromElement(fieldName: string): Promise<VirtualFile | null> {
    const file = this.dataBinding.getFile(fieldName, 0);
    if (file) {
      const fileNameParts = file.name.split('.');
      const fileName = fileNameParts.at(0) ?? '';
      const extension = `.${fileNameParts.pop()}` ?? '';
      const content = this.getContentAsString(await fileToBase64(file)) ?? '';
      // file content at this point should be a base64 encoded string after the content metadata with a comma as the delimiter
      const separatorIndex = content.indexOf(',');
      // get everything from beyond the comma. If there is no comma (index -1) this will return the whole string
      const fileContent = content.substring(separatorIndex + 1);
      return {
        name: fileName,
        extension: extension,
        content: fileContent,
        type: file.type,
        filePath: file.webkitRelativePath
      };
    }
    return null;
  }

  /**
   * Checks to see if the given element has a file uploaded to it.
   * @param fieldName The name of the bound element to be checked.
   * @returns true if a file has been uploaded, false otherwise.
   */
  public elementHasFile(fieldName: string): boolean {
    return this.dataBinding.getFile(fieldName, 0) !== null;
  }

  /**
   * Checks to see if the given element has a valid image file uploaded to it.
   * @param fieldName The name of the bound element to be checked.
   * @returns true if a valid image file has been uploaded, false otherwise.
   */
  public elementHasImageFile(fieldName: string): boolean {
    const file = this.dataBinding.getFile(fieldName, 0);
    if (file) {
      // TODO the file.type is autodetected from extension. We should Replace with magic word byte checking to be completely safe.
      // We can use file-type package which provides this functionality for us if we don't want to implement this ourselves.
      // https://github.com/sindresorhus/file-type
      return file.type.startsWith('image/');
    }
    return false;
  }

  /**
   * Uploads the given file data to a virtual file.
   * @param virtualFile Information about the file to be uploaded.
   * @returns A promise with the upload result.
   */
  public async uploadVirtualFile(virtualFile: VirtualFile) {
    return await this.blobApi.updateFileByVirtualPath({
      newVirtualPath: virtualFile.filePath,
      oldVirtualPath: '',
      data: virtualFile.content
    });
  }

  /**
   * Private helper function which takes the string output from fileToBase64 and returns it either as a string or null, converting the ArrayBuffer to a string if required.
   * @param content
   * @returns
   */
  private getContentAsString(content: string | ArrayBuffer | null): string | null {
    if (content) {
      // check to see if the content is already a string
      if (typeof content === 'string') {
        return content as string;
      }
      // if we get here, then we're dealing with an arraybuffer, so we'll need to read and decode it
      if (content instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(content as ArrayBuffer);
      }
      // ideally we shouldn't get here
      throw new Error(`content isn't a string or ArrayBuffer, but is instead ${content}`);
    }
    return null;
  }
}
