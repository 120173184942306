import {
  addCurrentUserEventListener,
  getCurrentUser,
  removeCurrentUserEventListener,
  setAfterApplyUserEvent,
  setUserClaims,
  UserPublicInfo
} from '../../webmodule-common/other/api/current-user';
import { appConfig, appConfigMenuPageItems } from './app-config';
import { AppIndex, MenuItem } from '../../webmodule-common/other/app-index';
import { applyDebugInformation } from './debug';
import { attachRouter, setRoutes } from '../../webmodule-common/other/router';
import { BlobApiImpl } from '../api/blob-api-impl';
import {
  clearCurrentUserFromSession,
  saveCurrentUserIntoSession,
  verifySession
} from '../../webmodule-common/other/user-session-verifier';
import { ConnectedState } from '../api/misc-codegen';
import { currentUserClaims } from '../../webmodule-common/other/currentuser-claims';
import { customElement, query } from 'lit/decorators.js';
import { DataAggregationApiImplementation } from '../api/supplier-api-dataaggregation-implementation';
import {
  DealerApiCommunications,
  setDealerTokenProvider
} from '../../webmodule-common/other/api/dealer-api-communications';
import { displayAllError } from '../../webmodule-common/other/ui/modal-errorhandler';
import { EventSnippet } from '../../webmodule-common/other/ui/events';
import { injectThemeClasses, isTestSite } from '../../webmodule-common/other/domain';
import { FranchiseeNetworkApiImplementation } from '../api/franchisee-network-api-implementation';
import { getApi, getApiFactory, setApiFactory, setApiInjector } from '../api/api-injector';

import { getUserLock } from './common/optimistic-user-lock';
import { goStaticURL } from '../../webmodule-common/other/ui/resource-resolver';
import { html, TemplateResult } from 'lit';
import { iconRegistry } from '../../webmodule-common/other/ui/icons/register-icons';
import { isEmptyOrSpace } from '../../webmodule-common/other/ui/string-helper-functions';
import { litModalScreenFactoryImpl } from '../../webmodule-common/other/ui/modal-factory-lit';
import { modalScreenFactoryImpl } from '../../webmodule-common/other/ui/modal-factory';
import { noSqlDb, NoSqlDb } from '../../webmodule-common/database/no-sql-db';
import { performPageLoadLoginVerifications } from '../api/dealer-pageload-verifications';
import { registerComponents, registerIconLibrary } from '../../webmodule-common/components/src/webmodule-components';
import { registerComponentsLocal } from '../../webmodule-common/other/ui/templatecontrols/component-registry';
import { runGlobalInit } from '../../webmodule-common/other/app-global-init';
import { ServiceResponseInvalid, ServiceResponseType } from '../../webmodule-common/interop/webmodule-interop';
import { setBeforeConstructionEvent } from '../../webmodule-common/other/async-constructor';
import { setCacheRegistry } from './cache-impl/cache-registry';
import { setErrorDialogEventHandler, showError } from '../../webmodule-common/other/ui/show-error';
import { setLitModalScreenFactory } from '../../webmodule-common/other/ui/modal/modal-factory-lit';
import { setModalScreenFactory } from '../../webmodule-common/other/ui/modal/modal-factory';
import { showDevelopmentError } from '../../webmodule-common/other/development-error';

import { strUserConnectFailed } from '../../webmodule-common/other/api/network-consts';
import { SupplierApiImplementation } from '../api/supplier-api-implementation';
import { supplierComponentRegistry } from './componentRegistry';
import { tlang } from '../../webmodule-common/other/language/lang';
import { userDataStore } from './common/current-user-data-store';
import { UserProfileCache } from './cache-impl/user-profile-cache';
import { webcomponentRegistry } from '../../webmodule-common/other/ui/webcomponent-registry';
import { _lockUIandExecute, setUILockAndExecuteEvent } from '../../webmodule-common/other/ui-lock';
import { responseHandler } from '../api/api-response-handler';
import { getPageController } from '../../webmodule-common/other/ui/pages/page-base';

function reload() {
  // Take us to the home page. this will clear any validations
  // or other issues
  // the home page has code to trigger login if required and
  // page reloading
  goStaticURL('/login');
}

async function connectUserAfterLogin() {
  const reset = async () => {
    userDataStore.clear();
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    reload();
  };
  const user = getCurrentUser();
  if (user !== null) {
    if (!(await verifySession())) {
      await reset();
      return;
    }

    //DO NOT MAKE ANY CALLS THAT NEED AUTHORIZATION BEFORE CONNECTING THE USER
    //Make any api calls or anything else here that are necessary to be used for the current user
    try {
      const state = await getApi().post<ConnectedState>('api/Supplier/ConnectUser', {});
      if (!state?.connected) throw new Error(strUserConnectFailed);

      //this will update the user claims security before we rebind the router.
      await userDataStore.loadCoreDetailsAfterLogin();
      rebindRouter();

      saveCurrentUserIntoSession();
    } catch (e) {
      await showDevelopmentError(e as Error);
      await reset();
    }
  } else {
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    userDataStore.clear();
    reload();
    //we always want to login again
  }
}

function rebindRouter() {
  const config = appConfig();
  setRoutes(config.routes);
  /*
    addURLResolvers(
        config.routes
            .filter((x) => x.resolveUrl !== undefined)
            .map((x) => {
                return x.resolveUrl as ResolveURLEntry;
            })
    );
    */
}

// this is our concrete top level application, everything else should be generic
@customElement('wm-supplier-app-index')
export class WebModuleSupplierAppIndex extends AppIndex {
  protected noSqlDb: NoSqlDb = noSqlDb;

  // Do not remove below line as this will stop icons from working on our site.
  protected icons = iconRegistry;
  protected webcomponents = webcomponentRegistry;
  protected supplierComponents = supplierComponentRegistry;
  @query('#main-body')
  private mainBody!: HTMLElement;

  constructor() {
    super();
    applyDebugInformation();
    rebindRouter();
    console.log(this.noSqlDb.dbName());
    registerIconLibrary('system', {
      resolver: name => `/assets/icons/${name}.svg`
    });

    registerIconLibrary('bootstrap', {
      resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/icons/${name}.svg`
    });

    registerIconLibrary('fa', {
      resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.substring(0, 4) === 'fas-') folder = 'solid';
        if (name.substring(0, 4) === 'fab-') folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.2/svgs/${folder}/${filename}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });

    this.userDisplayName = getCurrentUser()?.friendlyName;
  }

  async userStateChanged(_user: UserPublicInfo | null) {
    if (getCurrentUser() !== null) {
      await this.updateUIAspectsAfterLogin();
    } else this.userDisplayName = '';
  }

  connectedCallback(): void {
    super.connectedCallback();
    addCurrentUserEventListener(this);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    removeCurrentUserEventListener(this);
  }

  userNameMenuContent(): unknown {
    return html` <webmodule-icon library="fa" name="fas-user" slot="prefix"></webmodule-icon
      >${this.userDisplayName?.trim()}`;
  }

  globalSupportInformation(): string | TemplateResult {
    const cu = getCurrentUser();
    const cc = currentUserClaims();
    if (cc.isCynclyStaff) {
      return `CYNCLY Support connected to "${(cu as any).tenantName}"`;
    }
    return '';
  }

  customUserMenuElements(): TemplateResult {
    return html``;
  }

  async disconnectUser() {
    await getPageController().disposeAndRefresh();
    try {
      await getApi().post<ConnectedState>('api/Supplier/DisconnectUser', {});
      setUserClaims({});
    } catch {
      console.log(`Error disconnecting user`);
    }
    return true;
  }

  protected getMenuItems(): Array<MenuItem> {
    return appConfigMenuPageItems();
  }

  protected firstUpdated() {
    attachRouter(this.mainBody);
    let interval;
    const verifyUser = async () => {
      clearInterval(interval);
      await verifySession();
      interval = setInterval(verifyUser, 500);
    };
    interval = setInterval(verifyUser, 500);
    injectThemeClasses();
  }

  protected updateLogoTemplate() {
    const api = getApiFactory().blob();
    const positivelogoURl = isEmptyOrSpace(userDataStore.supplierSettings.positiveLogoVirtualPath)
      ? './assets/images/sample-logo-colour.png'
      : api.fullUrl(userDataStore.supplierSettings.positiveLogoVirtualPath);
    const negativelogoURl = isEmptyOrSpace(userDataStore.supplierSettings.negativeLogoVirtualPath)
      ? './assets/images/sample-logo-white.png'
      : api.fullUrl(userDataStore.supplierSettings.negativeLogoVirtualPath);
    const logoURl = isTestSite() ? negativelogoURl : positivelogoURl;
    this.logoTemplate = logoURl
      ? html`<img src=${logoURl} crossorigin="anonymous" class="img-fluid" alt="Brand" width="90" />`
      : html` <svg width="1" height="1"></svg>`;
  }

  private async updateUIAspectsAfterLogin() {
    await userDataStore.loadCoreDetailsAfterLogin();
    this.updateLogoTemplate();
    this.userDisplayName = getCurrentUser()?.friendlyName;
    this.requestUpdate();
  }
}

(function () {
  globalThis.webModulePrepareUserData = async _updateNote => {
    try {
      //we want to enforce that the system the user connects to is fully up to date. this is a
      //simple call to the v6 systems to check their version, and will ensure the system resets info
      //as needed. This is also required for the quotes page, so we know if we are a multi supplier view or not
    } catch {
      console.log('could not load suppliers');
    }
  };

  globalThis.webModuleBuildApplicationBindings = async _updateNote => {
    registerComponents();
    registerComponentsLocal();
    setUILockAndExecuteEvent(_lockUIandExecute);

    setLitModalScreenFactory(litModalScreenFactoryImpl);
    setModalScreenFactory(modalScreenFactoryImpl);

    setAfterApplyUserEvent(connectUserAfterLogin);

    setDealerTokenProvider(() => getUserLock());

    const errorDialogEventHandler = async (item: ServiceResponseInvalid | Error, title: EventSnippet) => {
      try {
        await displayAllError(title, item);
      } catch (e) {
        console.log(e);
      }
    };
    setErrorDialogEventHandler(errorDialogEventHandler);

    //any class using the async construct pattern will be assurred of a valid login
    //before the afterconstruction is called.
    //the new workflow does not require this anymore
    setBeforeConstructionEvent(async () => await userDataStore.loadCoreDetails());

    setApiFactory({
      supplier: () => new SupplierApiImplementation(getApi()),
      franchisee: () => new FranchiseeNetworkApiImplementation(getApi()),
      blob: () => new BlobApiImpl(getApi()),
      dataAggregation: () => new DataAggregationApiImplementation(getApi())
    });

    let _commSingleton: DealerApiCommunications | undefined;
    const apiInjecterEvent = () => {
      if (!_commSingleton)
        _commSingleton = new DealerApiCommunications('', responseHandler, () => {
          //Redirect to home page, next query will force a login to occur
          window.location.href = '/login';
        });
      return _commSingleton;
    };
    //Dependency inject an api for the entire application
    setApiInjector(apiInjecterEvent);
    setCacheRegistry(() => {
      const api = getApi();
      return {
        userProfile: new UserProfileCache(api)
      };
    });

    window.addEventListener('unhandledrejection', function (event) {
      if (event.reason.message.includes('Vaadin')) {
        return;
      }
      console.error(event.reason.stack);
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      showError(
        {
          responseType: ServiceResponseType.Error,
          responseTypeCaption: tlang`unhandled error`,
          responseError: {
            message: event.reason.message,
            stackTrace: event.reason.stackTrace
          }
        },
        () => tlang`Unhandled Error inside a promise occurred`
      );
    });

    runGlobalInit();
  };
})();

if (!performPageLoadLoginVerifications) console.log('missing page loader');
